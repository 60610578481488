import React, { useState, useEffect, MouseEvent, Fragment } from "react";
import { navigate } from "gatsby";
import Image from "gatsby-image";
import jump from "../jump";
import "./poster.css";

type Props = {
  hasLink?: boolean;
  isFixed?: boolean;
  gotoHome?: boolean;
  gotoHomeCallback?: (event: MouseEvent<HTMLAnchorElement>) => void;
  index: number;
  node: any;
  isFaded?: boolean | null; // true = isFaded, false = other poster faded, null = no posters faded
  setIsFaded: Function;
  hideDescription?: boolean;
};

export default function Poster({
  hasLink,
  isFixed,
  gotoHome,
  gotoHomeCallback,
  index,
  node,
  isFaded,
  setIsFaded,
  hideDescription
}: Props) {
  const [isGotoHomeVisible, setIsGotoHomeVisible] = useState(false);
  const title =
    (node.frontmatter && node.frontmatter.title) ||
    (node.fields && node.fields.slug) ||
    "";
  const { poster, gallery } = node.frontmatter;
  const galleries = gallery && gallery.filter(img => !!img);
  const fluidImage = poster
    ? poster.childImageSharp.fluid
    : galleries && galleries.length
    ? galleries[0].childImageSharp.fluid
    : null;
  const id = `id${index}`;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsGotoHomeVisible(true);
    }, 500);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  const gotoHomeCallbackWrapper = e => {
    setIsGotoHomeVisible(false);
    setIsFaded(false);
    gotoHomeCallback(e);
  };

  return (
    <Fragment>
      {gotoHome && (
        <a
          href="/"
          className={`poster__backhome ${
            isGotoHomeVisible ? "poster__backhome--visible" : ""
          }`}
          aria-label="Back to art"
          onClick={gotoHomeCallbackWrapper}
        >
          ← art
        </a>
      )}
      <div
        key={node.fields.slug}
        id={id}
        className={`poster ${isFixed ? " poster--is-fixed" : ""} ${
          isFaded === true ? " poster--is-faded" : ""
        } ${isFaded === false ? " poster--other-poster-faded" : ""} ${
          hasLink ? " poster--has-link" : ""
        }`}
        onClick={
          hasLink
            ? e => {
                e.preventDefault();
                e.stopPropagation();
                setIsFaded(true);

                const posters = Array.from(
                  document.querySelectorAll(".poster")
                );

                let targetFromTop = 0;
                for (let i = 0; i < posters.length; i++) {
                  const poster = posters[i];
                  if (poster.id === id) break;
                  const rect = poster.getBoundingClientRect();
                  targetFromTop += rect.height;
                }

                let timer;

                function actuallyNavigate() {
                  if (timer) clearTimeout(timer);
                  window.location.hash = id;
                  setTimeout(() => {
                    navigate(`${node.fields.slug}#${id}`);
                  }, 1000);
                }

                jump(targetFromTop, window.scrollY, {
                  callback: actuallyNavigate
                });
              }
            : null
        }
      >
        {fluidImage ? <Image fluid={fluidImage} /> : <div />}
        <div className="poster__info">
          <h2 className="poster__title">
            <a href={hasLink ? node.fields.slug : null}>{title}</a>
          </h2>
          {!hideDescription && (
            <a
              href={hasLink ? node.fields.slug : null}
              className="poster__text"
              dangerouslySetInnerHTML={{
                __html: node.frontmatter.description || node.excerpt
              }}
            ></a>
          )}
        </div>
      </div>
    </Fragment>
  );
}
