import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { slide as Menu } from "react-burger-menu";
import "what-input";
import "../verticalheight";
import "./layout.css";
import "../screen.scss";

type Props = {
  location: any;
  title?: string;
  children: any;
  className?: string;
};

function Layout({ location, title, children, className }: Props) {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          author
          social {
            twitter
            instagram
          }
        }
      }
    }
  `);
  const { social } = data.site.siteMetadata;

  return (
    <div className={className}>
      <Menu
        pageWrapId="page-wrap"
        outerContainerId="___gatsby"
        right
        styles={burgerStyles}
      >
        <Link to="/" className="menu-item">
          Art
        </Link>
        <Link to="/writing/" className="menu-item">
          Writing
        </Link>
        <Link to="/about/" className="menu-item">
          About
        </Link>
        <a
          href={`https://instagram.com/${social.instagram}`}
          title={`@${social.instagram}`}
          target="_blank"
          className="menu-item menu-item--social"
        >
          Instagram
        </a>
      </Menu>
      <main id="page-wrap">{children}</main>
    </div>
  );
}

export default Layout;

const burgerStyles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "40px",
    paddingBottom: "0px",
    right: "20px",
    top: "60px"
  },
  bmBurgerBars: {
    background: "#000",
    boxShadow: "1px 1px 3px #fff"
  },
  bmBurgerBarsHover: {
    background: "#333"
  },
  bmCrossButton: {
    height: "64px",
    width: "64px",
    right: "30px",
    top: "30px",
    zIndex: 2000
  },
  bmCross: {
    background: "#bbb",
    height: "40px"
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100vh"
  },
  bmMenu: {
    background: "#333",
    padding: "4.3em 1.5em 0",
    fontSize: "1.15em",
    maxHeight: "100%",
    overflow: "hidden"
  },
  bmMorphShape: {
    fill: "#373a47"
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0px 0.8em 0px 0.8em",
    margin: "0px"
  },
  bmItem: {
    display: "block",
    fontFamily: "sans-serif",
    fontSize: "1.5em",
    fontWeight: "100",
    color: "white",
    marginBottom: "0.5em",
    textDecoration: "none"
  },
  bmOverlay: {
    background: "transparent"
  }
};
