function isMobile() {
  if (typeof window === "undefined") return false;
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  );
}

if (isMobile()) {
  // 100vh changes during scroll on mobile
  // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
  // so we want to give them a fixed value and not cause jittering
  function sync() {
    // on mobile assume address bar is an
    // additional height as per the following formula
    const fullHeight = window.innerHeight * 1.2;
    document.documentElement.style.setProperty(
      "--allPercentHeight",
      `${fullHeight}px`
    );
    document.documentElement.style.setProperty("--allVh", `${fullHeight}px`);
  }
  window.addEventListener("orientationchange", () => {
    sync();
  });
  sync();
}
